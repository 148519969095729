document.addEventListener('DOMContentLoaded', function() {
    //Главный слайдер
    new Swiper('.main-slider', {
        loop: true,
        speed: 800,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.btns-pagination',
        },
        navigation: {
            nextEl: '.main-slider .btn-slider-next',
            prevEl: '.main-slider .btn-slider-prev',
        }
    })
    //Cлайдер популярных программ
    new Swiper('.list-slider .swiper', {
        loop: true,
        speed: 500,
        pagination: {
            el: '.btns-pagination',
        },
        navigation: {
            nextEl: '.list-slider .btn-slider-next',
            prevEl: '.list-slider .btn-slider-prev',
        },
        breakpoints: {
            1400: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 16,
            },
        }
    })
    //Cлайдер статей
    new Swiper('.articles-slider .swiper', {
        loop: true,
        speed: 800,
        autoHeight: true,
        spaceBetween: 30,
        pagination: {
            el: '.btns-pagination'
        },
        navigation: {
            nextEl: '.articles-slider .btn-slider-next',
            prevEl: '.articles-slider .btn-slider-prev',
        }
    })
    //Cлайдер сторудников
    const pagBullets = document.querySelector('.team-slider .head-square .btns-pagination')
    const pagBulletsMobile = document.querySelector('.team-slider .head .btns-pagination')
    new Swiper('.team-slider .swiper', {
        loop: true,
        speed: 500,
        autoHeight: true,
        slidesPerView: 1,
        spaceBetween: 16,
        pagination: {
            el: pagBullets
        },
        navigation: {
            nextEl: '.team-slider .btn-slider-next',
            prevEl: '.team-slider .btn-slider-prev',
        },
        breakpoints: {
            1400: {
                slidesPerView: 3,
                spaceBetween: 32,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            768: {
                pagination: {
                    el: pagBulletsMobile
                },
                slidesPerView: 3,
                spaceBetween: 16,
            },
            420: {
                pagination: {
                    el: pagBulletsMobile
                },
                slidesPerView: 2
            },
            320: {
                pagination: {
                    el: pagBulletsMobile
                }
            }
        }
    });

    //Меню для мобилки
    (function() {
        const burger = document.querySelector('.burger-menu')
        const nav = document.querySelector('header nav')
        if (!burger || !nav) return
        const closeBtn = nav.querySelector('.close-btn')
        burger.addEventListener('click', ev => {
            nav.classList.add('show')
        })
        closeBtn.addEventListener('click', ev => {
            nav.classList.remove('show')
        })
    })();

    //Для просмотра фоток в галерее
    (function() {
        Fancybox.bind("[data-fancybox]", {
            hideScrollbar: true,
        })
    })();

    //Скрывает часть текста
    (function() {
        document.querySelectorAll('[data-hide]').forEach((el, key) => {
            const max = +el.getAttribute('data-hide')
            if (el.innerText.length <= max) return
            const showText = el.innerText.slice(0, max)
            const hideText = el.innerText.slice(max)
            const id = 'collapse-' + key
            el.innerHTML = `
                <span class="collapse-wrapper">
                    <span class="collapse-min-text">${showText}</span>
                    <span class="collapse" id="${id}">${hideText}</span>
                    <span class="collapse-trigger" data-bs-toggle="collapse" href="#${id}"><span>Читать целиком</span><span>Скрыть</span></span>
                </span>
            `
            const btn = el.querySelector('.collapse-trigger')
            const box = el.querySelector('.collapse')
            const wrap = el.querySelector('.collapse-wrapper')
            new bootstrap.Collapse(btn)
            box.addEventListener('shown.bs.collapse', event => {
                wrap.classList.add('showed')
            })
            box.addEventListener('hidden.bs.collapse', event => {
                wrap.classList.remove('showed')
            })
        })
    })();

    //Поле оценки отзыва
    (function() {
        document.querySelectorAll('.form-rate').forEach(el => {
            el.addEventListener('mouseleave', ev => {
                const checkbox = el.querySelector('input:checked')
                if (checkbox) {
                    el.setAttribute('data-selected', checkbox.value)
                } else el.removeAttribute('data-selected')
            })
            el.querySelectorAll('.rate-item').forEach((item, index) => {
                let current = 0
                item.addEventListener('mouseenter', ev => {
                    current = index + 1
                    el.setAttribute('data-selected', current)
                })
            })
        })
    })();

    //Числовое поле с кнопками
    (function() {
        document.querySelectorAll('.form-number').forEach(el => {
            const input = el.querySelector('input')
            const viewSel = input.getAttribute('data-view')
            const oneSel = input.getAttribute('data-one-count')
            let view = null
            const one = +oneSel
            if (viewSel && !isNaN(one)) {
                view = document.querySelector(viewSel)
            }
            input.addEventListener('change', ev => {
                let value = parseInt(input.value)
                if (isNaN(value) || value < 1) {
                    input.value = 1
                }
                if (view) {
                    view.innerText = value * one
                }
            })
            el.querySelector('.minus').addEventListener('click', ev => {
                let count = parseInt(input.value) - 1
                count = count < 1 ? 1 : count
                input.value = count
                input.dispatchEvent(new Event('change'))
            })
            el.querySelector('.plus').addEventListener('click', ev => {
                let count = parseInt(input.value)
                if (isNaN(count)) count = 0
                input.value = count + 1
                input.dispatchEvent(new Event('change'))
            })
        })
    })();

    // Инициализация анимаций появления при скроллировании, верх
    (function() {
        if (document.querySelector(".js-hidden")) {
            new Animator(document.querySelectorAll(".js-hidden"));
        }
    })();

    // Открытие попапа если есть GET-параметр для этого
    (function() {
        const attr = 'data-show-onload'
        const urlParams = new URLSearchParams(window.location.search)
        const els = document.querySelectorAll(`[${attr}]`)
        for (let i = 0; i < els.length; i++) {
            const el = els[i]
            const attrValue = el.getAttribute(attr).split(':')
            if (attrValue.length < 2) continue
            if (urlParams.get(attrValue[0]) === attrValue[1]) {
                const modal = bootstrap.Modal.getOrCreateInstance(el)
                modal?.show()
                return
            }
        }
    })();
})

//Класс для анимации при скролле
class Animator {
    constructor(sections) {
        this.sections = sections
        this.init()
        this.show()
    }

    init() {
        window.addEventListener("scroll", () => {
            this.show()
        })
    }

    show() {
        this.sections.forEach(section => {
            if (!section.classList.contains('hidden')) return
            if (section.getBoundingClientRect().top < document.documentElement.clientHeight * .7) {
                section.classList.remove("hidden")
            }
        })
    }
}